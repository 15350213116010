
import * as uploadcareRuntime$Zqfzl2Mgol from '/app/node_modules/.pnpm/@nuxt+image@1.1.0_@planetscale+database@1.19.0_db0@0.3.1_drizzle-orm@0.36.4_@planetscal_1e78c8c21a49ae15be10460d0bcb3b00/node_modules/@nuxt/image/dist/runtime/providers/uploadcare'
import * as directusRuntime$7iJOjSxwS0 from '/app/node_modules/.pnpm/@nuxt+image@1.1.0_@planetscale+database@1.19.0_db0@0.3.1_drizzle-orm@0.36.4_@planetscal_1e78c8c21a49ae15be10460d0bcb3b00/node_modules/@nuxt/image/dist/runtime/providers/directus'
import * as ipxRuntime$AGT9jy5bqt from '/app/node_modules/.pnpm/@nuxt+image@1.1.0_@planetscale+database@1.19.0_db0@0.3.1_drizzle-orm@0.36.4_@planetscal_1e78c8c21a49ae15be10460d0bcb3b00/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp",
    "webp"
  ]
}

imageOptions.providers = {
  ['uploadcare']: { provider: uploadcareRuntime$Zqfzl2Mgol, defaults: {"cdnURL":"https://ucarecdn.com/"} },
  ['directus']: { provider: directusRuntime$7iJOjSxwS0, defaults: {"baseURL":"https://directus-staging-mhgotunjaq-an.a.run.app/assets/"} },
  ['ipx']: { provider: ipxRuntime$AGT9jy5bqt, defaults: {"maxAge":31536000} }
}
        